import { useEffect, useState } from "react";
import './Header.css';
import TitleCard from "./components/TitleCard"
import { HashLink as Link } from 'react-router-hash-link';

function Header() {
  const [navbarResponsive, setNavbarResponsive] = useState(false);
  const [navbarClass, setNavbarClass] = useState("main-navbar");

  const toggleNavbar = () => setNavbarResponsive(navbarResponsive => !navbarResponsive);

  useEffect(() => {
    if (navbarResponsive) {
      setNavbarClass(navbarClass => navbarClass + " responsive");
    } else {
      setNavbarClass("main-navbar");
    }
  }, [navbarResponsive]);
    return (
      <>
        <nav id="main-navbar" className={navbarClass}>
          <ul>
            <li><Link to="#about" id="first" onClick={toggleNavbar}>About</Link></li>
            <li><Link to="#experience" onClick={toggleNavbar}>Experience</Link></li>
            <li><Link to="#certificates-awards-anchor" onClick={toggleNavbar}>Awards</Link></li>
            <li><Link to="#projects" onClick={toggleNavbar}>Volunteering / Projects</Link></li>
            <li><Link to="#contact" onClick={toggleNavbar}>Contact</Link></li>
            <li><Link to="#0" id="navbaricon" className="icon" onClick={toggleNavbar}>
              <img src="resources/images/menuIcon.png" height="20vw" alt="Mobile menu button" /></Link>
            </li>
          </ul>
        </nav>
        {/* The name div */}
        <div className="parallax">
          {/* The animated main name */}
          <TitleCard img_enabled='true' img_src='./resources/images/profile3.JPG' />
        </div>
      </>
    )
}


export default Header;
