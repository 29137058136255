import "./Experience.css"
import ProjectCard from "./components/ProjectCard"

function Experience() {
    return (
      <>
        <div className="section" id="experience-section">
          {/* eslint-disable-next-line */}
          <a name="experience" id="experience" className="anchor"></a> 
          <h2 className="header">Technical Experience</h2>
          <center>Here are some highlights of my technical work experience:</center>
          <div className="projects-container">
            <ProjectCard
              title="Software Engineering Co-op"
              subtitle="Salsify"
              location="Boston, MA"
              wide="true"
              dates="Jan 2022 - Feb 2023"
              text="I worked for Salsify for 6 months as a co-op, and for another 6 months as a part-time engineer. I enhanced search suggestion scope and accuracy for 1900+ customer organizations by enabling administrators to define custom properties as searchable. I also reduced initial blocking time by over 90% when editing large quantities of items by developing a lazy-loading item grid for the Ember framework. I worked with the Ruby-on-Rails backend, Typescript & Ember frontend, and worked with the various GraphQL APIs."
              img_src="resources/images/salsify.jpg"
              tags="Ember,Typescript,Ruby,Ruby on Rails"
            />

            <ProjectCard
              title="Project Lead"
              subtitle="SearchNEU"
              location="Boston, MA"
              wide="true"
              dates="Sep 2021 - May 2023"
              text="I led a team of 7 developers and designers for a course search engine with 6,500+ unique monthly users. As project lead, I defined project scope, planned agile cycles, and promoted test-drive development and code reviews. I automated web scraping tasks in AWS, removing manual oversight and improving data refresh frequency, refactored the legacy Javascript backend to Typescript, introducing typed database and GraphQL support, and increased code coverage by 18% and improved visibility by re-architecting the automated testing pipeline."
              img_src="resources/images/searchNeu.png"
              tags="TypeScript,Next.js,GraphQL,Elasticsearch,PostgreSQL,Docker,AWS,Terraform"

            />

            <ProjectCard
              title="Computer Science Teaching Assistant"
              subtitle="Northeastern University"
              location="Boston, MA"
              wide="true"
              dates="Sep. 2021 - Dec. 2021"
              text="After taking Foundations of Cybersecurity, I was asked by my former professor to return as a TA. My work consists of holding office hours to provide guidance on homework and to aid understanding of class material, as well as grading student assignments and quizzes"
              img_src="resources/images/neu.png"
              tags="Python,Docker"
            />

            <ProjectCard
              title="DevOps Software Engineering Co-op"
              subtitle="Wolters Kluwer"
              location="Waltham, MA"
              wide="true"
              dates="Jan. 2021 - Aug. 2021"
              text="I helped mitigate 2 customer-impacting incidents by re-architecting an internal Grafana dashboard monitoring production assets, increasing visibility and alerting. I also created containerized applications for scraping, parsing, and exposing Prometheus metrics to expand monitoring coverage of vital internal infrastructure. I worked with Docker, Python, Kubernetes, Java, Grafana, Azure, Prometheus, AWX, and Terraform, among other technologies."
              img_src="resources/images/wolterskluwer.jpg"
              tags="Python,Docker,Kubernetes,Azure,Ansible,Terraform,Grafana,Alertmanager,Prometheus,Jenkins,Jira,Flask"
            />
          </div>
        </div>
        
        <div className="parallax"></div>
        <div className="section" id="prevwork-section">
          {/* eslint-disable-next-line */}          
          <a name="certificates-awards-anchor" id="certificates-awards-anchor" className="anchor"></a>
          <h2 className="header">Certificates / Awards</h2>
          <div className="projects-container">
            <ProjectCard
              title="Azure Fundamentals (AZ-900)"
              short="true"
              dates="Aug. 2021"
              text="The Azure Fundamentals exam was a great opportunity to reinforce the cloud-computing concepts I learned on my first co-op, as well as to expand my skillset. "
              img_src="resources/images/az900.png"
            />

            <ProjectCard
              title="Regeneron WESEF - 2nd Place"
              subtitle="Computer Science category"
              short="true"
              dates="2019"
              text="I collaborated with an interdisciplinary research team at the MIT Department of Civil and Environmental engineering on a study of biomimetic synthetic spider silk. My work on the computational models earned 2nd place in my category at WESEF."
              img_src="resources/images/wesef.jpg"
            />

            <ProjectCard
              title="Intel Excellence in Computer Science Award"
              short="true"
              subtitle="Awarded at WESEF"
              dates="2019"
              text="The Intel Excellence in Computer Science award was awarded to me at the Regeneron Westchester Science & Engineering Fair (WESEF) in 2019, for the same work that earned me second place in my category. For more details, see 'Geometric Web Optimization' in the 'Projects' section"
              img_src="resources/images/intel.png"
            />
          </div>
        </div>
      </>
    )
}

export default Experience;
