import React from 'react';
import './ProjectCard.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons"; 
import { motion } from "framer-motion";

function ProjectLink(link, isGitHub) {
	let linkIcon = (isGitHub === "true") ? faGithub : faLink;

	return (link) ? (
		<a className="project-link" target="_blank" rel="noopener noreferrer" href={link} >
			<FontAwesomeIcon icon={linkIcon} size="lg" /> &#8594;
		</a>
	) : null;
}

function LanguageTag(text) {
	return (
		<span key={text} className="language-tag">
			{text}
		</span>
	);
}


class ProjectCard extends React.Component {
	constructor(props) {
		super(props);

		this.tags = props.tags ? props.tags.split(",").map((name) => LanguageTag(name)) : [];
		this.link = ProjectLink(props.link, props.isGithubLink)
	}
	render() {
		return (
			<motion.div className={`project-card ${this.props.wide === 'true' ? 'wide' : ''} ${this.props.short === 'true' ? 'short' : ''}`} whileHover={{ scale: 1.05 }}>
				<img className="card-img" src={this.props.img_src} alt={this.props.title} />

				<div className="card-content">
					<div className="card-header-div">
						<div className="card-header-titlelink">
							<h4 className="card-title">{this.props.title}</h4>
							<h4>{this.link}</h4>
						</div>
						{this.props.subtitle ? <h6>{this.props.subtitle}</h6> : null}
						{this.props.location ? <h6>{this.props.location}</h6> : null}
						{this.props.dates ? <h6>{this.props.dates}</h6> : null}
					</div>
					
					
					<p className="card-text text-secondary">{this.props.text}</p>

					<div className="language-tags">
						{this.tags}
					</div>
				</div>
			</motion.div>
	);
	}
}


export default ProjectCard;