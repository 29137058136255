import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Routes } from "react-router-dom";
import { default as Strata } from "./components/Strata"
import { default as Home } from "./components/Home"


ReactDOM.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/strata" element={<Strata />} />
    </Routes>
  </HashRouter>,

  document.getElementById("root")
);

// ReactDOM.render(
//   <div className="projects-container">
//     <ProjectCard
//       title="Software Engineering Co-op"
//       subtitle="Salsify"
//       location="Boston, MA"
//       wide="true"
//       dates="Jan. 2022 - Present"
//       text="This was my second (and final) co-op, starting in 2022. I was asked to — and happily accepted! — an offer to continue working with the Workflow team part-time. As a member of the Workflow team, I develop and debug Salsify's task-based system of work, allowing corporate customers to consolidate efforts and optimize business processes. My work consists of full-stack development, primarily in Ruby (and Rails) and Ember with Typescript."
//       img_src="resources/images/salsify.jpg"
//       tags="Ember,Typescript,Ruby,Ruby on Rails"
//     />

//     <ProjectCard
//       title="Computer Science Teaching Assistant"
//       subtitle="Northeastern University"
//       location="Boston, MA"
//       wide="true"
//       dates="Sep. 2021 - Dec. 2021"
//       text="After taking Foundations of Cybersecurity, I was asked by my former professor to return as a TA. My work consists of holding office hours to provide guidance on homework and to aid understanding of class material, as well as grading student assignments and quizzes"
//       img_src="resources/images/neu.png"
//       tags="Python,Docker"
//     />

//     <ProjectCard
//       title="DevOps Software Engineering Co-op"
//       subtitle="Wolters Kluwer"
//       location="Waltham, MA"
//       wide="true"
//       dates="Jan. 2021 - Aug. 2021"
//       text="This was my first co-op at Northeastern, and was a great opportunity to explore a professional working environment. I worked with the Referential Site Reliability Engineering team, and with the CloudSRE team. With the former, I worked on on-premises applications, including provisioning via Ansible, monitoring with the GAP stack, and contributing to the in-house deployment pipeline. With the latter, I worked on the cloud assets with Azure, worked on deployments with Terraform (and provisioning with Ansible), as well as contributing to a Kubernetes-based Grafana dashboard monitoring production assets. "
//       img_src="resources/images/wolterskluwer.jpg"
//       tags="Python,Docker,Kubernetes,Azure,Ansible,Terraform,Grafana,Alertmanager,Prometheus,Jenkins,Jira,Flask"
//     />
//   </div>,
//   document.getElementById("experience-root")
// );

// ReactDOM.render(
//   // Intel Excellence in Computer Science Award (2019)
//   <div className="projects-container">
//     <ProjectCard
//       title="Azure Fundamentals (AZ-900)"
//       short="true"
//       dates="Aug. 2021"
//       text="The Azure Fundamentals exam was a great opportunity to reinforce the cloud-computing concepts I learned on my first co-op, as well as to expand my skillset. "
//       img_src="resources/images/az900.png"
//     />

//     <ProjectCard
//       title="Regeneron WESEF - 2nd Place"
//       subtitle="Computer Science category"
//       short="true"
//       dates="2019"
//       text="I collaborated with an interdisciplinary research team at the MIT Department of Civil and Environmental engineering on a study of biomimetic synthetic spider silk. My work on the computational models earned 2nd place in my category at WESEF."
//       img_src="resources/images/wesef.jpg"
//     />

//     <ProjectCard
//       title="Intel Excellence in Computer Science Award"
//       short="true"
//       subtitle="Awarded at WESEF"
//       dates="2019"
//       text="The Intel Excellence in Computer Science award was awarded to me at the Regeneron Westchester Science & Engineering Fair (WESEF) in 2019, for the same work that earned me second place in my category. For more details, see 'Geometric Web Optimization' in the 'Projects' section"
//       img_src="resources/images/intel.png"
//     />
//   </div>,
//   document.getElementById("certs-root")
// );

// ReactDOM.render(
//   <div className="projects-container">
//     <ProjectCard
//       title="Family Tree Viewer"
//       text="An interactive web-based family tree viewer. This project parses and standardizes GEDCOM files into JSON data, and generates a Typescript-based interactive site displaying the family tree and associated personal details."
//       img_src="resources/images/familyTreeViewer.png"
//       tags="Python,TypeScript,JSON,HTML,CSS,Github Pages"
//       link="https://github.com/hankewyczz/familyTreeViewer"
//       isGithubLink="true"
//     />

//     <ProjectCard
//       title="Lisova Shkola"
//       text="I designed and managed the website and media marketing of an international campaign raising over $91,000 for the purchase/distribution of tactical medical aid to the Ukrainian frontlines.
//       Lisova Shkola — an international scouting group mentoring young adults — organized contacts with medical suppliers, transport to Europe, and last-mile distribution in Ukraine."
//       img_src="resources/images/lisovashkola.png"
//       tags="HTML,CSS,JavaScript,PHP,SQL"
//       link="https://lisovashkola.org"
//       isGithubLink="false"
//     />

//     <ProjectCard
//       title="OptionsBot"
//       text="A Python-based Discord bot for fetching stock and options information, 
// 					graphing changes, and paper-trading. JSON data is fetched and parsed from Yahoo's financial API"
//       img_src="resources/images/optionsBot.png"
//       tags="Python,JSON"
//       link="https://github.com/hankewyczz/OptionsBot"
//       isGithubLink="true"
//     />

//     <ProjectCard
//       title="PlanNEU"
//       text="A schedule-planning tool for Northeastern University students. This TypeScript-based project 
// 					fetches course data, parses it, and generates all possible schedule combinations based on a set 
// 					of user-selected filters"
//       img_src="resources/images/planNeu.png"
//       tags="JSON,TypeScript,HTML,CSS"
//       link="https://github.com/hankewyczz/PlanNEU"
//       isGithubLink="true"
//     />

//     <ProjectCard
//       title="TwitterToRedditBot"
//       text="This Python-based program was created for posting a specific Twitter user's newest tweets 
// 					(eg. breaking news) to a specific Reddit subreddit. The program checks for duplicate posts before
// 					posting, and accesses Twitter's and Reddit's APIs using Tweepy and PRAW, respectively"
//       img_src="resources/images/github.jpg"
//       tags="Python"
//       link="https://github.com/hankewyczz/TwitterToRedditBot"
//       isGithubLink="true"
//     />

//     <ProjectCard
//       title="Geometric Web Optimization"
//       text="As part of an interdisciplinary research project at the MIT Department of Civil and 
// 					Environmental Engineering, these scripts created physical and computational 3D web-structure models.

// 					My work earned the Intel Excellence in Computer Science Award, 
// 					and placed 2nd in my category at WESEF."
//       img_src="/resources/images/openscad.png"
//       tags="Python,OpenSCAD,MATLAB"
//       link="https://github.com/hankewyczz/Web-Structure-Modeling"
//       isGithubLink="true"
//     />

//     <ProjectCard
//       title="Bing Cache Scraper"
//       text="This project was created for the non-profit organization WikiSpiv, after their provider servers 
// 					(and backups) failed.  By scraping the Bing cache to recover old cached pages, this project 
// 					helped recreate the site. "
//       img_src="resources/images/github.jpg"
//       tags="Python"
//       link="https://github.com/hankewyczz/bing-cache-scraper"
//       isGithubLink="true"
//     />

//     <ProjectCard
//       title="Personal Site"
//       text="This site! This is a personal playground for new web-design projects. 
// 					New frameworks, new technologies - this is where I test and deploy them. 
// 					For example, these project cards were my first foray into ReactJS.
// 					The site is deployed using Github Pages"
//       img_src="resources/images/personalSite.png"
//       tags="HTML,CSS,JavaScript,ReactJS"
//       link="#"
//       isGithubLink="false"
//     />
//   </div>,
//   document.getElementById("project-root")
// );

// let textArr = [
//   "Welcome to my site!",
//   "I'm an undergraduate student at Northeastern University",
//   "I'm in the Honors Program, and am majoring in Computer Science with a minor in History.",
//   "In my free time, I'm active in the Ukrainian Cultural Club, Sandbox (a student-led software consultancy), and intramural sports.",
//   "I also volunteer as a scout leader in Boston, and actively participate in the Ukrainian diaspora here.",
//   "Take a look at the Projects section to see some of the other cool projects I've been working on!",
// ];

// ReactDOM.render(
//   <Terminal texts={textArr} />,
//   document.getElementById("terminal-root")
// );

// ReactDOM.render(<TitleCard img_enabled='true' img_src='./resources/images/profile3.JPG' />, document.getElementById("animated-name-root"));
