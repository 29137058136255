import Header from "./home/Header"
import AboutText from "./home/AboutText";
import Experience from "./home/Experience";
import Projects from "./home/Projects"
import Contact from "./home/Contact"


function Home() {
  return (
    <>
      <Header />
      {/* <About /> */}
      <AboutText />
      <div className="parallax" />
      <Experience />
      <div className="parallax" />
      <Projects /> 
      <Contact />
    </>
    );
}



// ReactDOM.render(
//   // Intel Excellence in Computer Science Award (2019)
  
//   document.getElementById("certs-root")
// );

// ReactDOM.render(
  
//   document.getElementById("project-root")
// );






export default Home;