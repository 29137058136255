import ProjectCard from "./components/ProjectCard"

function Projects() {
    return (
        <>
          <div className="section" id="prevwork-section">
            {/* eslint-disable-next-line */}
            <a name="projects" id="projects" className="anchor" />
            <h2 className="header">Projects</h2>
            <div className="projects-container">
            <ProjectCard
              title="Family Tree Viewer"
              text="An interactive web-based family tree viewer. This project parses and standardizes GEDCOM files into JSON data, and generates a Typescript-based interactive site displaying the family tree and associated personal details."
              img_src="resources/images/familyTreeViewer.png"
              tags="Python,TypeScript,JSON,HTML,CSS,Github Pages"
              link="https://github.com/hankewyczz/familyTreeViewer"
              isGithubLink="true"
            />

           

            <ProjectCard
              title="Lisova Shkola"
              text="I designed and managed the website and media marketing of an international campaign raising over $91,000 for the purchase/distribution of tactical medical aid to the Ukrainian frontlines.
              Lisova Shkola — an international scouting group mentoring young adults — organized contacts with medical suppliers, transport to Europe, and last-mile distribution in Ukraine."
              img_src="resources/images/lisovashkola.png"
              tags="HTML,CSS,JavaScript,PHP,SQL"
              link="https://lisovashkola.org"
              isGithubLink="false"
            />

            <ProjectCard
              title="OptionsBot"
              text="A Python-based Discord bot for fetching stock and options information, 
                  graphing changes, and paper-trading. JSON data is fetched and parsed from Yahoo's financial API"
              img_src="resources/images/optionsBot.png"
              tags="Python,JSON"
              link="https://github.com/hankewyczz/OptionsBot"
              isGithubLink="true"
            />

            <ProjectCard
              title="PlanNEU"
              text="A schedule-planning tool for Northeastern University students. This TypeScript-based project 
                  fetches course data, parses it, and generates all possible schedule combinations based on a set 
                  of user-selected filters"
              img_src="resources/images/planNeu.png"
              tags="JSON,TypeScript,HTML,CSS"
              link="https://github.com/hankewyczz/PlanNEU"
              isGithubLink="true"
            />

            <ProjectCard
              title="TwitterToRedditBot"
              text="This Python-based program was created for posting a specific Twitter user's newest tweets 
                  (eg. breaking news) to a specific Reddit subreddit. The program checks for duplicate posts before
                  posting, and accesses Twitter's and Reddit's APIs using Tweepy and PRAW, respectively"
              img_src="resources/images/github.jpg"
              tags="Python"
              link="https://github.com/hankewyczz/TwitterToRedditBot"
              isGithubLink="true"
            />

            <ProjectCard
              title="Geometric Web Optimization"
              text="As part of an interdisciplinary research project at the MIT Department of Civil and 
                  Environmental Engineering, these scripts created physical and computational 3D web-structure models.

                  My work earned the Intel Excellence in Computer Science Award, 
                  and placed 2nd in my category at WESEF."
              img_src="/resources/images/openscad.png"
              tags="Python,OpenSCAD,MATLAB"
              link="https://github.com/hankewyczz/Web-Structure-Modeling"
              isGithubLink="true"
            />

            <ProjectCard
              title="Bing Cache Scraper"
              text="This project was created for the non-profit organization WikiSpiv, after their provider servers 
                  (and backups) failed.  By scraping the Bing cache to recover old cached pages, this project 
                  helped recreate the site. "
              img_src="resources/images/github.jpg"
              tags="Python"
              link="https://github.com/hankewyczz/bing-cache-scraper"
              isGithubLink="true"
            />

            <ProjectCard
              title="Personal Site"
              text="This site! This is a personal playground for new web-design projects. 
                  New frameworks, new technologies - this is where I test and deploy them. 
                  For example, these project cards were my first foray into ReactJS.
                  The site is deployed using Github Pages"
              img_src="resources/images/personalSite.png"
              tags="HTML,CSS,JavaScript,ReactJS"
              link="#"
              isGithubLink="false"
            />
          </div>
          </div>
        </>
    )
}

export default Projects;
