import "./Contact.css"

function Contact() {
  return (
    <>
      <div className="section" id="contact-section">
      {/* eslint-disable-next-line */}
      <a name="contact" id="contact" className="anchor" alt="Contact information"></a>
      <div id="contact-social">
        {/* eslint-disable-next-line */}
        <a href="https://keybase.io/hankewyczz" alt="Keybase">
        <img src="resources/images/key.png" alt="Keybase" /></a>
        {/* eslint-disable-next-line */}
        <a href="https://github.com/hankewyczz/" alt="GitHub">
        <img src="resources/images/github-light.png" alt="GitHub" /></a>
        {/* eslint-disable-next-line */}
        <a href="https://www.linkedin.com/in/zacharh/" alt="Linkedin">
        <img src="resources/images/linkedin-light.svg" alt="Linkedin" /></a>
      </div>
    </div>
    </>
  )
}

export default Contact;
