import "./About.css"

function About() {
  return (
    <>
      {/* About section */}
      <div className="ukraine">
        <div className="flag-top"></div>
        <div className="flag-bottom"></div>
        <div className="ukraine-text">
          <h2>RESPONDING TO THE INVASION OF UKRAINE</h2>
          <p>
            This war is not a new one, but the people of Ukraine need help now more than ever. Take a minute - learn more about Ukraine's history,
            what her people have been through, and if you can, donate to a Ukrainian cause.
          </p>
          <h4>Donate</h4>
          <ul>
            <li><a href="https://lisovashkola.org/donations.php#updates">Lisova Shkola</a> is a small group comprised largely of former US servicemembers and combat medics (of Ukrainian descent) using their connections to obtain critical medical supplies
                and handle last-mile delivery via contacts in Ukraine. (Disclaimer - I volunteer for this group)
            </li>
            <li><a href="https://www.razomforukraine.org/">Razom</a> (meaning "together") focuses on tactical and humanitarian medical aid</li>
            <li><a href="https://www.comebackalive.in.ua/">Повернись Живим</a> (Come Back Alive) supplies tactical gear, UAVs, training, and armor</li>
          </ul>
          <h4>Learn</h4>
          <ul>
            <li><a href="https://huri.harvard.edu/news/timothy-snyder-kyivs-ancient-normality-redux">Kyivan Rus'</a> (and an analysis of Russian pseudo-history related to it)</li>
            <li><a href="https://www.husj.harvard.edu/">Harvard's Ukrainian Research Institute</a></li>
            <li><a href="https://www.husj.harvard.edu/articles/mapping-the-great-famine">Holodomor</a> (the 1932-33 Ukrainian famine-genocide)</li>
            <li><a href="https://www.husj.harvard.edu/articles/the-nation-in-a-nutshell-ukrainian-displaced-persons-camps-in-postwar-germany">Post-WWII Displaced Persons camps</a></li>
            <li><a href="https://www.harpersbazaar.com/culture/features/a39224859/ukraine/">The perspective of the Ukrainian-American diaspora</a> (<a href="https://medium.com/@coreyrak/what-my-grandmother-was-trying-to-tell-me-4ff5fb31749d">and another view</a>)</li>
          </ul>
          <h2>Слава Україні! Героям Слава!</h2>
        </div>
      </div>
      <div className="section">
        {/* eslint-disable-next-line */}
        <a name="about" id="about" className="anchor" />
        {/* TAKE FROM LINKEDIN PROFILE */}
          <p>I'm a recent graduate from Northeastern University (B.S. in Computer Science, <span class="font-italic">summa cum laude)</span> with prior professional experience with both frontend and backend work.
          </p>
          <p>
          I've also volunteered for Sandbox — a non-profit software consultancy — as the lead of a 7-person team of developers and designers. In my spare time, I volunteer for various groups in the Ukrainian diaspora — scouting (both in Boston and elsewhere), the Ukrainian Cultural Center of New England, and various other groups in New York. 
          </p>
      </div>
    </>
  )
}

export default About;
