import React from 'react';
import './TitleCard.css';


class TitleCard extends React.Component {
	render() {
		return (
			<div className="profile-container">
				
				
				<h1>Zachar Hankewycz</h1>
				<div className='profile-photo-container'>
				{ this.props.img_enabled === "true" ? 
					<img className='profile-photo-img' src={ this.props.img_src } 
					alt='Profile' />
				 : null }
				 </div>
				
			</div>);
	}
}

export default TitleCard;